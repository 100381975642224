.clipboard {
    color: #ccc;
    cursor: pointer;   
}

.clipboard:hover {
    color: #999;
}

.time {
    text-align: right;
    margin-bottom: 1em;
}

.result {
    font-size: 3em;
    margin-top: 1em;
}

.accuracy-res {
    font-size: 1.8em;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.topimg{
    max-width: 100%;
    max-height: 50vh;
}

.startbutton{
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.time-res {
    font-size: 1.8em;
    margin-bottom: 0.5em;
}

.tweet {
    margin-bottom: 2em;
}

.papers {
    font-size: 0.8em;
}

.paperList{
    margin-bottom: 2em;
}

.stategreen {
    background-color: #21ba45;
    height: 1em;
}

.statered {
    background-color: #db2828;
    height: 1em;
}

.stategrey {
    background-color: #999;
    height: 1em;
}

.papertitle {
    font-size: 0.8em;
    text-align: right;
}

.masktoken {
    margin-left: 0.2em;
    margin-right: 0.2em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    background-color: #f2711c;
    font-weight: bold;
}

.correcttoken {
    margin-left: 0.2em;
    margin-right: 0.2em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    background-color: #21ba45;
    font-weight: bold;
}

.wrongtoken {
    margin-left: 0.2em;
    margin-right: 0.2em;
    padding-left: 0.1em;
    padding-right: 0.1em;
    background-color: #db2828;
    font-weight: bold;
}